export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	methods: {
		auto: function( propertyName , label = true ) {
			return {
				property: this.schema.properties[propertyName] ,
				modelValue: this.document[propertyName] ,
				originalValue: this.document?.[propertyName] ,
				label: label
			} ;
		}
	}
} ;