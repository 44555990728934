import Collection from '@/libRestQuery/js/Collection.js' ;

export default {
	schema: function() {
		if ( ! this.ready ) return null ;
		return this.restQueryCollection.schema ;
	} ,
	document: ( state ) => {
		return ( id ) => {
			return state.documents[ id ]
				|| state.documents[ state.documentsSlugs[ id ] ]
				|| state.documents[ state.documentsHids[ id ] ]
				|| null ;
		} ;
	} ,
	collection: ( state ) => {
		return ( query = {} ) => {
			var url = Collection.queryToString( query ) ;
			return state.collections[ url ]?.map( id => state.documents[ id ] ) ;
		} ;
	} ,
	collectionMeta: ( state ) => {
		return ( query = {} ) => {
			var url = Collection.queryToString( query , true ) ;
			return state.collectionsMeta[ url ] ;
		} ;
	}
} ;
