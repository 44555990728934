<template>
	<a class="value file" :href="value.path" target="_blank">
		{{ value.filename }}
	</a>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Object ,
			default: () => {}
		}
	}
} ;
</script>
