<template>
	<input type="checkbox" class="value boolean" :checked="value" disabled />
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Boolean ,
			default: false
		}
	}
} ;
</script>
