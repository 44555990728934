export default async function( data ) {
	const pdfMake = ( await import( 'pdfmake/build/pdfmake' ) ).default ;
	const invoices = ( await import( './invoices.js' ) ).default ;

	// Bug with vfs and vite, using cdn for now
	// const pdfFonts = ( await import( 'pdfmake/build/vfs_fonts' ) ).default ;
	// pdfMake.vfs = pdfFonts.pdfMake.vfs ;


	const pdfMakeFonts = {
		Roboto: {
			normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf' ,
			bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf' ,
			italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf' ,
			bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
		}
	} ;
	pdfMake.fonts = pdfMakeFonts ;

	pdfMake.createPdf( invoices( data ) ).download() ;
}