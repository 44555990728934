export default {
	props: {
		modelValue: {
			type: [String , Object] ,
			default: () => {}
		} ,
		operator: {
			type: String ,
			default: '$eq' ,
			validator( value ) {
				return [
					'$eq' ,
					'$in' ,
					'$lt' ,
					'$lte' ,
					'$gt' ,
					'$gte' ,
					'$ne' ,
					'$nin'
				].includes( value ) ;
			}
		} ,
		property: {
			type: Object ,
			required: true
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		value: {
			get() {
				if ( typeof this.modelValue === 'string' ) return this.modelValue ;

				var defaultValue = null ;
				switch( this.operator ) {
					case '$in':
					case '$nin':
						defaultValue = [] ;
						break ;
				}
				if ( this.modelValue && typeof this.modelValue[this.operator] !== 'undefined' ) {
					return this.modelValue[this.operator] ;
				}
				return defaultValue ;
			} ,
			set( value ) {
				if ( typeof this.modelValue === 'string' ) return this.emitValue( value ) ;

				return this.emitValue( Object.assign( {} , this.modelValue , {
					[this.operator]: value
				} ) ) ;
			}
		} ,
		hasFilters: function() {
			if ( typeof this.value === 'object' && this.value !== null ) {
				return !! Object.values( this.value ).filter( a => !! a.length ).length ;
			}
			return typeof this.value !== 'undefined' && this.value !== null ;
		} ,
		reset: function() {
			this.value = null ;
		}
	} ,
	methods: {
		emitValue: function( value ) {
			console.log( '$emit filter' , value ) ;
			this.$emit( 'update:modelValue' , value ) ;
		}
	}
} ;
