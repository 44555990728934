import { defineStore } from 'pinia' ;

import state from './state.js' ;
import actions from './actions.js' ;
import getters from './getters.js' ;

import restQuery from '@/restQuery/index.js' ;

var collections = {} ;

export default function( collectionName ) {
	// Capitalize collectionName
	collectionName = collectionName.charAt( 0 ).toUpperCase() + collectionName.slice( 1 ) ;

	if ( ! collections[collectionName] ) {
		collections[collectionName] = defineStore( `RestQuery: ${collectionName}` , {
			state ,
			getters: {
				...getters ,
				restQueryCollection: () => restQuery.Collection( collectionName )
			} ,
			actions
		} ) ;
	}

	var store = collections[collectionName]() ;
	store.getSchema() ;
	return store ;
}
