<template>
	<input
		v-model="value"
		type="date"
		min="2000-01-01"
		class="value"
		:class="{hasFilters: hasFilters}"
	/>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	props: {
		operator: {
			type: String ,
			default: '$gt' ,
			validator( value ) {
				return [
					'$eq' ,
					'$lt' ,
					'$lte' ,
					'$gt' ,
					'$gte'
				].includes( value ) ;
			}
		}
	}
} ;
</script>
