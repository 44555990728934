import Document from './Document.js' ;
import Schema from './Schema.js' ;

export default function Collection( restQuery , name , autoloadSchema = true ) {
	if ( ! restQuery || ! name ) {
		throw new Error( 'RestQuery.Collection: Invalid parameters' ) ;
	}

	this.restQuery = restQuery ;
	this.name = name ;
	this.localName = this.restQuery.i18n.get( this.name , this.name ) ;

	this.schema = null ;

	if ( autoloadSchema ) this.getSchema() ;
}

Collection.prototype.document = function( id ) {
	return new Document( this.restQuery , this , id ) ;
} ;

Collection.prototype.rawQuery = function( options ) {
	let path = [this.name] ;

	if ( options.restQueryMethod ) {
		path.push( options.restQueryMethod ) ;
	}
	else if ( options.queryString?.method ) {
		path.push( options.queryString.method ) ;
		delete options.queryString.method ;
	}

	if ( options.queryString && typeof options.queryString !== 'string' ) {
		options.queryString = Collection.queryToString( options.queryString ) ;
	}

	return this.restQuery.fetch( path.join( '/' ) , options ) ;
} ;


/**************************/
/* Helpers, call rawQuery */
/**************************/

Collection.prototype.get = async function( query = {} ) {
	await this.getSchema() ;

	return this.rawQuery( {
		method: 'GET' ,
		queryString: {
			...query ,
			populate: Object.keys( this.schema.populatables )
		}
	} ) ;
} ;

Collection.prototype.create = async function( document ) {
	return this.rawQuery( {
		method: 'POST' ,
		body: Document.diff( document , this.schema.createWorkingDocument() )
	} ) ;
} ;

Collection.prototype.method = function( restQueryMethod , options ) {
	return this.rawQuery( Object.assign( {
		method: 'POST' ,
		restQueryMethod: restQueryMethod
	} ,	options ) ) ;
} ;

Collection.prototype.getSchema = function( force = false ) {
	if ( ! force && this.schema ) return Promise.resolve( this.schema ) ;

	return this.schema = this.method( 'SCHEMA' , { method: 'GET' } )
		.then( schema => this.schema = new Schema( this , schema ) ) ;
} ;

/******************/
/* Static methods */
/******************/
Collection.unflatQuery = function( queryRoute ) {
	// FIXME: parseInt on some values? Work without, but...

	var query = {} ;

	for( let [key , value] of Object.entries( queryRoute ) ) {
		if ( ! value ) continue ;

		if ( ['limit' , 'skip' , 'search' , 'method'].includes( key ) ) {
			query[key] = value ;
		}
		else if ( key.startsWith( 'sort' ) ) {
			query.sortName = key.split( '.' ).pop() ;
			query.sortOrder = value ;
		}
		else if ( key.startsWith( '.' ) ) {
			let filter = key.slice( 1 ) ;

			let [property , operator] = filter.split( '.' ) ;
			if ( operator === '$in' || operator === '$nin' ) {
				value = value.slice( 1 , - 1 ).split( ',' ) ;
			}

			query.filters ??= {} ;
			query.filters[property] ??= {} ;

			Object.assign( query.filters[property] , {
				[operator || '$eq']: value
			} ) ;
		}
		else {
			// not special params
			query.params ??= {} ;
			query.params[key] = value ;
		}

		if ( ['limit' , 'skip'].includes( key ) ) {
			query[key] = parseInt( value , 10 ) ;
		}
	}

	return query ;
} ;

Collection.flatQuery = function( query , onlyModifier ) {
	// ORDER IS IMPORTANT !
	// All arrays needs to be sorted, this function need to be deterministic
	// FIXME BIG: Should probably use encodeURI or encodeURIComponent on values
	var queryObject = {} ;
	if ( query.method ) queryObject.method = query.method ;

	if ( query.search ) queryObject.search = query.search
		.replace( /-/g , ' ' )
		.replace( / +/g , ' ' )
		// .replace( /[^a-zA-Z0-9]+/g , ' ' )
		.trim()
		.toLowerCase() ;

	if ( query.filters ) {
		for( let [propertyName , property] of Object.entries( query.filters ) ) {
			for( let [operator , value] of Object.entries( property ) ) {
				if ( typeof value === 'undefined' || value === null || ! operator ) continue ;

				let filterValue = value ;
				if ( Array.isArray( value ) ) {
					if ( ! value.length ) continue ;
					// filterValue = '[' + value.sort().join( ',' ) + ']' ;
					filterValue = ArrayToString( value ) ;
				}

				queryObject[`.${propertyName}.${operator}`] = filterValue ;
			}
		}
	}

	if ( query.params ) {
		Object.assign( queryObject , query.params ) ;
	}

	if ( ! onlyModifier ) {
		if ( query.skip ) queryObject.skip = query.skip ;
		if ( query.limit ) queryObject.limit = query.limit ;
		if ( query.populate?.length ) queryObject.populate = ArrayToString( query.populate ) ;

		if ( query.sortName && query.sortOrder )
			queryObject[`sort.${query.sortName}`] = query.sortOrder ;

		if ( query.deepPopulate )
			queryObject[`deepPopulate.${query.deepPopulate.collection}`] = ArrayToString( query.deepPopulate.populate ) ;
	}

	return queryObject ;
} ;

var ArrayToString = function( array ) {
	return `[${array.sort().join( ',' )}]` ;
} ;


Collection.queryToString = function( query , onlyModifier ) {
	var queryObject = this.flatQuery( query , onlyModifier ) ;
	return Object.entries( queryObject )
		.sort()
		.map( entry => entry.join( '=' ) )
		.join( '&' ) ;
} ;
