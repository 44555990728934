export default {
	props: {
		property: {
			type: Object ,
			required: true
		} ,
		modelValue: {
			required: true
		} ,
		originalValue: {
			required: false
		} ,
		disabled: {
			type: Boolean ,
			default: false
		} ,
		placeholder: {
			type: String ,
			default: null
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		value: {
			get() {
				return this.modelValue ;
			} ,
			set( value ) {
				this.emitValue( value ) ;
			}
		} ,
		required: function() {
			return ! this.property.optional ;
		}
	} ,
	methods: {
		emitValue: function( value ) {
			console.log( '$emit' , value , this.$.type.name ) ;
			this.$emit( 'update:modelValue' , value ) ;
		}
	}
} ;