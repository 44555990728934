<template>
	<div class="wrapper mobile">
		<Navigation v-if="connected" />
		<main id="main" class="main">
			<slot>
				APP NOT FOUND
			</slot>
		</main>
	</div>
	<Account v-if="connected" />
</template>

<script>
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

import Account from '../components/Account.vue' ;
import Navigation from '../components/Navigation.vue' ;

export default {
	components: {
		Account ,
		Navigation
	} ,
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	computed: {
		connected: function() {
			return !! this.accountStore.session ;
		}
	}
} ;
</script>

<style scoped>
.wrapper {
	nav {
		height: 3.5em;
		width: 100vw;
		position: fixed;
		bottom: 0;
		padding-left: 4em;
		padding-bottom:0em;
		box-sizing: border-box;
	}

	main {
		margin-left: 0em;
		margin-bottom: 3.5em;
		min-height: 100vh;
		display: grid;
	}

	@media (max-width: 800px) {
		font-size: 16px;
	}
}
</style>