<template>
	<component :is="componentType" :to="to" :class="classType">
		<Icon-text :name="icon" :text="text" />
	</component>
</template>

<script>
export default {
	props: {
		icon: {
			type: String ,
			required: true
		} ,
		text: {
			type: String ,
			default: ''
		} ,
		classType: {
			type: String ,
			default: 'button'
		} ,
		to: {
			type: [String , Object] ,
			default: null
		}
	} ,
	computed: {
		componentType: function() {
			return this.to ? 'router-link' : 'button' ;
		}
	}
} ;
</script>