export default {
	toDateObject: function( date ) {
		if ( ! ( date instanceof Date ) ) {
			if ( typeof date === 'string' ) {
				date = new Date( date ) ;
			}
		}
		return date ;
	} ,
	toLocaleDateString: function( date ) {
		if ( ! date ) return null ;
		date = this.toDateObject( date ) ;
		return date.toLocaleDateString( undefined , {
			//weekday: 'long',
			year: 'numeric' ,
			month: '2-digit' ,
			day: '2-digit'
		} ) ;
	} ,
	dateYearUTC: function( date ) {
		if ( ! date ) return null ;
		date = this.toDateObject( date ) ;
		return `${date.getUTCFullYear()}` ;
	} ,
	dateUTC: function( date ) {
		if ( ! date ) return null ;
		date = this.toDateObject( date ) ;

		let year = date.getUTCFullYear().toString().padStart( 4 , '0' ) ,
			month = ( date.getUTCMonth() + 1 ).toString().padStart( 2 , '0' ) ,
			day = date.getUTCDate().toString().padStart( 2 , '0' ) ;

		return [year , month , day].join( '-' ) ;
	} ,
	timeUTC: function( date ) {
		if ( ! date ) return null ;
		date = this.toDateObject( date ) ;

		let hours = date.getUTCHours().toString().padStart( 2 , '0' ) ,
			minutes = date.getUTCMinutes().toString().padStart( 2 , '0' ) ;

		return [hours , minutes].join( ':' ) ;
	} ,
	datetimeUTC: function( date ) {
		if ( ! date ) return null ;
		return [this.dateUTC( date ) , this.timeUTC( date )].join( ' ' ) ;
	}
} ;
