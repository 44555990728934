<template>
	<Button-icon class="account" icon="help" :text="accountStore.session?.userLogin.slice(0,2).toUpperCase()" :to="{path:'/Account'}" />

	<!--<div class="options">
			<Button-icon class="option" :icon="config.darkMode?'dark_mode':'light_mode'" :text="i18n('option.brightness')" @click="config.switchDarkMode()" />
			<Button-icon class="option" :icon="config.layout==='Desktop'?'computer':'smartphone'" :text="i18n('option.display')" @click="config.switchMobile()" />
			<Button-icon class="option" :icon="'help'" :text="i18n('option.infos')" :to="{path:'/Infos'}" />
			<Button-icon class="option" :icon="'power_settings_new'" :text="i18n('option.logout')" @click="disconnect" />
		</div>-->
</template>

<script>
import Config from '@/stores/Config.js' ;
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

export default {
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	data: function() {
		return {
			config: Config()
		} ;
	} ,
	methods: {
		disconnect: function() {
			this.accountStore.disconnect()
				.then( () => {
					this.$router.push( {
						name: 'Login'
					} ) ;
				} ) ;
		}
	}
} ;
</script>

<style scoped>
.account {
	background-color:var(--color-text);
	color:var(--color-background);
	font-weight:800;
	position: fixed;
	left: 1em;
	bottom: 0.8em;
	padding:0.5em;
	z-index: 2;
	height:2.5em;
	width:2.5em;
	border-radius:1em;


	@media (max-width: 800px) {
		left:unset;
		right:1em;
		line-height: 2.5em;
		text-align: center;
	}
}
/*
.account {
	display: grid;
	grid-auto-flow:column;
	grid-gap: 0.4em;
	position: fixed;
	top: 0em;
	right: 0em;
	padding:0.5em;
	z-index: 2;

	.button {
		border-radius:unset;
		background-color:transparent;
		color:inherit;
		border:none;
		box-shadow:unset;
		font-size:2em;
	}
	&:hover .options {
		transform: translate(0);
	}
	.options{
		position:absolute;
		right:0;
		top:0;
		width:300px;
		background-color:var(--color-background);
		height:calc(100vh);
		box-shadow: 0 3px 6px 4px var(--color-smooth);
		display:grid;
		align-items: center;
		transition: all 0.3s ease-out;
		transform: translate(100%);

		&:first-child{
			height:3.5em;
		}

		.option {
			height:100%;
			display:grid;
			place-content: center;
			place-items: center;
			cursor:pointer;
			border-bottom:solid 1px var(--color-smooth);

			&:hover{
				background-color:var(--color-focus);
				color:var(--color-background);
			}
		}
	}
}
.user {
	padding: 0.5em 1em;
	cursor: pointer;
	border-radius:0.5em 0.5em;
	margin-left: 2em;
}
	*/
</style>