export default function Schema( collection , schema ) {
	// FIXME: remove collection here, used only for
	// i18n stuff
	this.collection = collection ;

	this.schema = schema ;

	Object.assign( this , this.SuperSchema() , { properties: schema.properties } ) ;
}

Schema.prototype.SuperSchema = function( fragmentSchema , path = [] ) {
	/* NOTICE:
	You probably want to rewrite parts of this function, and it's probably a good idea
	But it's not a 10 minutes job, and if you try to rewrite without taking the time,
	you're gonna get bit.
	Sincerely, your past self
	*/
	var schema = fragmentSchema || this.schema ;

	var bob = {
		populatables: {} ,
		editables: {} ,
		contents: {} ,
		indexes: {} ,
		searchables: {} ,
		filtrables: {}
	} ;

	var indexedProperties = this.getIndexedProperties() ;

	for( let [name , property] of Object.entries( schema.properties ) ) {
		let localPath = Array.from( path ) ;
		localPath.push( name ) ;

		property.name = name ;
		property.localName = this.collection.restQuery.i18n.get( localPath.join( '.' ) , this.collection.name ) ;

		// FIXME MAYBE
		indexedProperties.forEach( indexedProperty => {
			if ( indexedProperty.startsWith( property.name ) ) {
				bob.indexes[name] = property ;

				if ( this.isFiltrableProperty( property ) ) {
					bob.filtrables[name] = property ;
				}
			}
		} ) ;
		/*
		if ( indexedProperties.includes( property.name ) ) {
			indexed[property.name] = property ;
		}
		*/

		if ( this.isContentProperty( property ) ) {
			bob.contents[name] = property ;

			if ( this.isEditableProperty( property ) ) {
				if ( this.isPopulatableProperty( property ) ) {
					bob.populatables[name] = property ;
				}

				/*
				FIXME: child populate is all shitty
				need to rewrite isChildPopulatableProperty and
				the code below
				*/
				if ( this.isChildPopulatableProperty( property ) ) {
					Object.entries( property.of.properties ).forEach( ( [childName , childProperty] ) => {
						if ( this.isPopulatableProperty( childProperty ) ) {
							bob.populatables[`${name}.*.${childName}`] = childProperty ;
						}
					} ) ;
				}

				bob.editables[name] = property ;
				// FIXME: BEWARE, is more tricky than it seems,
				// need to be recursive, as strictObject can have sub schema
				if ( this.isEmbeddedProperty( bob.editables[name] ) ) {
					bob.editables[name].of.properties = this.SuperSchema.call( this , bob.editables[name].of , localPath ).editables ;
				}
			}
		}
	}

	return bob ;
} ;

Schema.prototype.createWorkingDocument = function( document = {} , fragmentSchema = null ) {
	var schemaProperties = fragmentSchema?.properties || this.editables ;
	var properties = {} ;

	for( let [name , property] of Object.entries( schemaProperties ) ) {
		properties[name] = this.createWorkingProperty( document[name] , property ) ;
	}

	return properties ;
} ;

Schema.prototype.createWorkingProperty = function( documentValue , property ) {
	let value = typeof property.default === 'undefined' ? null : property.default ;

	if ( typeof documentValue !== 'undefined' ) {
		if ( this.isEmbeddedProperty( property ) ) {
			value = ( documentValue || [ {} ] )
				.map( document => this.createWorkingDocument( document , property.of ) ) ;
		}
		else if ( property.type === 'link' ) {
			value = documentValue?._id ;
		}
		else if ( property.type === 'multiLink' ) {
			value = documentValue?.map( link => link._id ) ;
		}
		else {
			value = documentValue ;
		}
	}

	return structuredClone( value )	;
} ;


Schema.prototype.isSearchableProperty = function( property ) {
	alert( 'FIXME: isSearchableProperty not working' , property ) ;
} ;

Schema.prototype.isFiltrableProperty = function( property ) {
	if ( ! this.isContentProperty( property ) ) return false ;

	if ( property.type === 'restQuery.hid' ) return false ;
	/* Should be remove at some point */
	if ( property.type === 'array' ) return false ;
	if ( property.type === 'backLink' ) return false ;
	if ( property.type === 'strictObject' ) return false ;

	if ( ['string' ,
		'email' ,
		'number' ,
		'integer' ,
		'float'].includes( property.type ) && ! property.in ) return false ;

	// console.log( property.name , property.type , !! property.in ) ;
	return true ;
} ;


Schema.prototype.isEditableProperty = function( property ) {
	return property.tags.includes( 'content' ) ;
} ;

Schema.prototype.isContentProperty = function( property ) {
	return property.tags.includes( 'content' ) || property.tags.includes( 'system-content' ) ;
} ;

Schema.prototype.isEmbeddedProperty = function( property ) {
	// return property.type === 'strictObject' && property.of ;
	return property.type === 'array' && property.of ;
} ;

Schema.prototype.isPopulatableProperty = function( property ) {
	return ['link' , 'multiLink' , 'backLink'].includes( property.type ) ;
} ;

Schema.prototype.isChildPopulatableProperty = function( property ) {
	if ( ! this.isEmbeddedProperty( property ) ) return false ;
	return Object.values( property.of.properties ).some( property => this.isPopulatableProperty( property ) ) ;
} ;

Schema.prototype.isIndexed = function() {

} ;

Schema.prototype.getIndexedProperties = function() {
	var indexes = new Set() ;
	for( let index of this.schema.indexes ) {
		for( let [key , value] of Object.entries( index.properties ) ) {
			if ( value === 'text' ) continue ;
			if ( ['parent.id' , 'slugId'].includes( key ) ) continue ;
			indexes.add( key ) ;
		}
	}
	return Array.from( indexes ) ;
} ;
