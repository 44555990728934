<template>
	<div v-if="edit" class="actions">
		<div class="actions-start">
			<Button-icon icon="undo" :text="i18n('go_back')" @click="action('switchEdit')" />
		</div>
		<Button-icon icon="save" class="ok" :text="i18n('save')" @click="action('saveAndDisableEdit')" />
	</div>
	<div v-else class="actions">
		<div class="actions-start">
			<Button-icon icon="undo" :text="i18n('go_back')" :to="navigation.back" />
		</div>

		<Button-icon icon="download" :text="i18n('download_invoice')" @click="action('exportPdf')" />
		<Button-icon icon="file_copy" :text="i18n('duplicate')" @click="action('duplicate')" />
		<Button-icon icon="edit" :text="i18n('edit')" @click="action('switchEdit')" />
	</div>
</template>

<script>
import Navigation from '@/stores/Navigation.js' ;

export default {
	inheritAttrs: false ,
	props: {
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	emits: ['action:exec'] ,
	data: function() {
		return {
			navigation: Navigation()

		} ;
	} ,
	methods: {
		action: function( name , args ) {
			this.$emit( `action:exec` , name , args ) ;
		}
	}
} ;
</script>

<style scoped src="../css/actions.css" />
