<template>
	<div class="document splitdesk">
		<section>
			<header>{{ i18n('deposits.content') }}</header>
			<div class="section-content">
				<input-combo v-model="value['carousel']" v-bind="auto('carousel')" :default-query="carouselQuery" />
				<input-combo v-model="value['products']" v-bind="auto('products')" />
			</div>
		</section>
		<div>
			<section>
				<header>{{ i18n('deposits.identification') }}</header>
				<div class="section-content">
					<div class="grid-align">
						<input-combo v-model="value['client']" v-bind="auto('client')" />
						<input-combo v-model="value['orderNumber']" v-bind="auto('orderNumber')" />
						<input-combo v-model="value['state']" v-bind="auto('state')" />
						<input-combo v-model="value['orderDate']" v-bind="auto('orderDate')" />
						<input-combo v-model="value['departureDate']" v-bind="auto('departureDate')" />
						<input-combo v-model="value['deliveryDate']" v-bind="auto('deliveryDate')" />
					</div>
				</div>
			</section>
			<section>
				<header>{{ i18n('deposits.comment') }}</header>
				<div class="section-content">
					<input-combo v-model="value['deliveryComment']" v-bind="auto('deliveryComment')" />
				</div>
			</section>
			<section v-if="!edit" class="meta">
				<div class="section-content grid-align">
					<input-combo v-bind="auto('creationDate')" />
					<input-combo v-bind="auto('creationUser')" />
					<input-combo v-bind="auto('modificationDate')" />
					<input-combo v-bind="auto('modificationUser')" />
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import mixin from './mixin.js' ;
import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;

export default {
	mixins: [mixin] ,
	data: function() {
		return {
			carouselQuery: {
				filters: {
					archived: {
						$eq: false
					}
				}
			}
		} ;
	} ,
	watch: {
		'value.carousel': function() {
			if ( ! this.value.carousel || typeof this.value.carousel !== 'string' ) return ;

			Collections( 'Carousels' ).restQueryCollection.document( this.value.carousel ).get()
				.then( document => {
					this.value['products'] = document.products
						.filter( product => product.product?._id )
						.map( product => {
							return {
								product: { _id: product.product._id } ,
								quantity: product.quantity
							} ;
						} ) ;
				} ) ;
		}
	}
} ;
</script>

