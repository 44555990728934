export default {
	trim: function( str , char = ' ' ) {
		var trimStart = this.trimStart( str , char ) ;
		var trimEnd = this.trimEnd( trimStart , char ) ;
		return trimEnd ;
	} ,

	trimStart: function( str , char = ' ' ) {
		while( str.charAt( 0 ) === char ) {
			str = str.substr( 1 ) ;
		}
		return str ;
	} ,
	trimEnd: function( str , char = ' ' ) {
		while( str.charAt( str.length - 1 ) === char ) {
			str = str.substr( 0 , str.length - 1 ) ;
		}
		return str ;
	} ,

	capitalize: str => str.charAt( 0 ).toUpperCase() + str.slice( 1 ) ,
	unCapitalize: str => str.charAt( 0 ).toLowerCase() + str.slice( 1 ) ,
	isUpperCase: str => str === str.toUpperCase() ,
	isLowerCase: str => str === str.toLowerCase() ,
	dedupSlash: str => str.replace( /\/+/g , '/' ) ,
	dedupSpace: str => str.replace( / +/g , ' ' )

	/*
	mapReplace: function( str , map ) {
		var i , keys , length , from , to ;

		keys = Object.keys( map ) ;
		length = keys.length ;

		for ( i = 0 ; i < length ; i++ ) {
			from = keys[ i ] ;
			to = map[ from ] ;
			str = str.replace( new RegExp( from , 'g' ) , to ) ;
		}

		return str ;
	} ;
	*/
} ;
