export default function I18n( i18n , fallbackName ) {
	this.dictionnaries = i18n ;
	this.fallbackName = fallbackName || 'misc' ;

	if ( ! this.dictionnaries[this.fallbackName] ) {
		this.dictionnaries[this.fallbackName] = {} ;
	}
}

I18n.prototype.addDictionnary = function( dictionnary , space = this.fallbackName ) {
	Object.assign( this.dictionnaries[space] , dictionnary ) ;
} ;

I18n.prototype.get = function( key , space = this.fallbackName ) {
	var str = this.dictionnaries[space]?.[key] ;
	if ( ! str ) {
		// console.log( 'I18N key not found' , key , space ) ;
		str = key.split( '.' ).pop() ;
	}
	return str ;
} ;