<template>
	<div class="wrapper" :class="{ fullscreen: !connected }">
		<Navigation v-if="connected" />
		<main id="main" class="main">
			<slot>
				APP NOT FOUND
			</slot>
		</main>
	</div>
	<div>
		<Account v-if="connected" />
	</div>
</template>

<script>
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

import Account from '../components/Account.vue' ;
import Navigation from '../components/Navigation.vue' ;

export default {
	components: {
		Account ,
		Navigation
	} ,
	setup() {
		return { accountStore: AccountStore() } ;
	} ,
	computed: {
		connected: function() {
			return !! this.accountStore.session ;
		} ,
		wrapperClass: function() {
			return {
				fullscreen: ! this.connected
			} ;
		}
	}
} ;
</script>

<style scoped>
.wrapper {
	nav {
		width: min-content;
		height: 100vh;
		position: fixed;
		padding-bottom: 4em;
		box-sizing: border-box;
	}

	main {
		margin-left: 3.5em;
		min-height: 100vh;
		display: grid;
	}

	@media (max-width: 800px) {
		font-size: 16px;

		nav {
			height: min-content;
			width: 100vw;
			position: fixed;
			bottom: 0;
			padding-bottom: 0em;
			padding-right:4em;
		}

		main {
			margin-left: 0em;
			margin-bottom: 3.5em;
		}
	}
}



/*
.wrapper {
	display: grid;
	grid-template-columns: 0fr 1fr;
	height:100%;
	width: 100vw;

	@media (max-width: 1500px) {
		grid-template-columns: 1fr;
	}
}

.wrapper main {
	width: 100%;
}

.wrapper.fullscreen {
	grid-template-columns: 1fr;
	height: 100vh;
	overflow: hidden;
	place-items: center;
}

.wrapper.fullscreen main {
	height: 100%;
	overflow: auto;
}
	*/
</style>