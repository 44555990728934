export default function( globbedComponents , prefix ) {
	prefix = prefix ? prefix + '-' : '' ;
	var entries = Object.entries( globbedComponents ).map( ( [path , definition] ) => {
		return [prefix + path.split( '/' ).pop().replace( /\.\w+$/ , '' ) , definition.default] ;

		/* Buggy code, as it add to imported json the "name" property,
		// but maybe name property is used somewhere.
		// To remove after some time
		definition.default.name = prefix + path.split( '/' ).pop().replace( /\.\w+$/ , '' ) ;
		return [definition.default.name , definition.default] ;
		*/
	} ) ;

	return Object.fromEntries( entries ) ;
}