<template>
	<!--
	Use $route.params.app instead of $route.path/fullPath to not re-render
	on url changes
	-->
	<Debug />
	<DebugButton />

	<component :is="getLayout">
		<component :is="getComponent" :key="$route.params.app" />
	</component>
</template>

<script>
import AccountStore from '@/libRestQuery/vue/store/Account/index.js' ;

import Config from '@/stores/Config.js' ;

import Desktop from '@/layouts/Desktop.vue' ;
import Mobile from '@/layouts/Mobile.vue' ;

import Home from './Home.vue' ;
import Login from './Login.vue' ;
import Infos from './Infos.vue' ;
import Account from './Account.vue' ;

import debugApp from './debugApp/Main.vue' ;
import zenparc from './zenparc/Main.vue' ;

export default {
	components: {
		Desktop ,
		Mobile ,

		Home ,
		Login ,
		Infos ,
		Account ,

		debugApp ,
		zenparc
	} ,
	setup() {
		return {
			accountStore: AccountStore() ,
			config: Config()
		} ;
	} ,
	computed: {
		getLayout: function() {
			return this.config.layout || 'Desktop' ;
		} ,
		getComponent: function() {
			if ( this.$route.name !== 'Apps' ) {
				return this.$route.name ;
			}

			switch( this.$route.params.app ) {
				case 'Home':
					return this.$route.params.app ;
				default:
					// return 'debugApp' ;
					return 'zenparc' ;
			}
		}
	} ,
	watch: {
		'accountStore.session': function() {
			this.redirectOnSession() ;
		}
	} ,
	mounted: function() {
		this.redirectOnSession() ;
	} ,
	methods: {
		redirectOnSession: function() {
			if ( this.accountStore.session && this.$route.name === 'Login' ) {
				this.$router.push( { name: 'Home' } ) ;
			}
			else if ( ! this.accountStore.session && this.$route.meta.requiresAuth ) {
				this.$router.push( { name: 'Login' } ) ;
			}
		}
	}
} ;
</script>
