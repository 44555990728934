import { watch , inject , computed , ref } from 'vue' ;

export default function( props , context ) {
	const schema = inject( 'schema' ) ;

	const debounceSearchTimeout = ref( null ) ;

	const filterList = computed( () => {
		if ( props.filters ) {
			return Object.fromEntries( props.filters.map( name => [name , schema.value.filtrables[name]] ) ) ;
		}
		return schema.value.filtrables ;
	} ) ;

	const query = computed( {
		get() {
			return props.modelValue ;
		} ,
		set( query ) {
			context.emit( 'update:modelValue' , query ) ;
		}
	} ) ;
	const debounceSearch = computed( {
		get() {
			return query.value.search ;
		} ,
		set( search ) {
			clearTimeout( debounceSearchTimeout.value ) ;
			debounceSearchTimeout.value = setTimeout( () => {
				query.value.search = search ;
			} , 500 ) ;
		}
	} ) ;

	watch( () => query , () => {
		context.emit( 'update:modelValue' , query.value ) ;
	} , { deep: true } ) ;

	return {
		schema ,
		debounceSearch ,
		query ,
		filterList
	} ;
}
