<template>
	<div class="multiselect" :class="{hasFilters: hasFilters}">
		<div class="list">
			<input v-model="query.search" type="text" placeholder="Search" class="search" />

			<button v-if="hasFilters" class="reset" @click="reset">@</button>

			<div class="collection">
				<label v-for="document in getCollection" :key="document._id" class="name">
					<input
						:value="getLinkId(document)"
						:name="property.name"
						type="checkbox"
						:indeterminate="isIndeterminate(getLinkId(document))"
						:checked="isChecked(getLinkId(document))"
						@input="update"
					/>
					{{ document.hid || document.slugId || document._id }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		defaultQuery: {
			type: Object ,
			default: () => {}
		}
	} ,
	setup( props , context ) {
		return SetupCollection( props , context , {
			// FIXME: really bad!!! In case of anyCollection, force use Users collection
			collection: props.property?.collection || 'Users' ,
			query: Object.assign( {
				sortName: 'hid' ,
				sortOrder: '1' ,
				limit: 10
			} , props.defaultQuery ) ,
			autoload: true ,
			queryStats: false
		} ) ;
	} ,
	computed: {
		getCollection: function() {
			if ( ! this.ready ) return [] ;

			if ( this.query.search ) return this.collection ;

			return [
				...this.value.$in.map( documentId => this.store.document( documentId ) ) , ...this.value.$nin.map( documentId => this.store.document( documentId ) )
			] ;
		} ,
		value: {
			get() {
				return Object.assign( {
					$in: [] ,
					$nin: []
				} , this.modelValue ) ;
			} ,
			set( value ) {
				return this.emitValue( value ) ;
			}
		}
	} ,
	methods: {
		getLinkId: function( document ) {
			var id = document._id ;
			if ( this.property.anyCollection ) id += '@' + document._collection ;
			return id ;
		} ,
		isChecked: function( value ) {
			return this.value.$in.includes( value ) ;
		} ,
		isIndeterminate: function( value ) {
			return ! this.value || ( ! this.value.$in.includes( value ) && ! this.value.$nin.includes( value ) ) ;
		} ,
		update: function( event ) {
			var $in = new Set( this.value.$in || [] ) ;
			var $nin = new Set( this.value.$nin || [] ) ;

			if ( event.target.checked ) {
				if ( this.value.$nin.includes( event.target.value ) ) {
					event.target.indeterminate = true ;
					event.target.checked = false ;

					$in.delete( event.target.value ) ;
					$nin.delete( event.target.value ) ;
				}
				else {
					$in.add( event.target.value ) ;
					$nin.delete( event.target.value ) ;
				}
			}
			else {
				$nin.add( event.target.value ) ;
				$in.delete( event.target.value ) ;
			}

			this.value = {
				$in: Array.from( $in ) || null ,
				$nin: Array.from( $nin ) || null
			} ;
		}
	}
} ;
</script>
