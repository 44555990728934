<template>
	<div class="link multiselect">
		<input ref="value" type="text" :value="humanValue" :placeholder="placeholder" :required="required" disabled />

		<div v-if="!disabled" class="list">
			<input v-model="query.search" type="text" placeholder="Search" class="search value" @focus="refresh()" />

			<div class="collection">
				<label v-for="document in getCollection" :key="document._id" class="name">
					<input v-model="value" :value="getLinkId(document)" :name="property.name" type="radio" @click="reset" />
					{{ document.hid || document.slugId || document._id }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	inject: {
		originalDocument: {
			from: 'document'
		} ,
		originalSchema: {
			from: 'schema'
		}
	} ,
	props: {
		modelValue: {
			type: [Object , String] ,
			default: () => {}
		} ,
		defaultQuery: {
			type: Object ,
			default: () => {}
		}
	} ,
	setup( props , context ) {
		return SetupCollection( props , context , {
			// FIXME: really bad!!! In case of anyCollection, force use Users collection
			collection: props.property?.collection || 'Users' ,
			query: Object.assign( {
				sortName: 'hid' ,
				sortOrder: '1' ,
				limit: 10
			} , props.defaultQuery ) ,
			autoload: true ,
			queryStats: false
		} ) ;
	} ,
	computed: {
		value: {
			get() {
				return this.modelValue?._id || this.modelValue ;
			} ,
			set( value ) {
				var document = this.findDocument( value ) ;
				this.setValidity( !! document ) ;

				this.emitValue( value ) ;
			}
		} ,
		humanValue: function() {
			return this.findDocument( this.value )?.hid || this.value ;
		} ,
		getCollection: function() {
			if ( ! this.value || ( this.query.search && this.ready ) ) {
				return this.collection ;
			}
			else if ( this.value && this.findDocument( this.value ) ) {
				return [this.findDocument( this.value )] ;
			}
			return [] ;
		}
	} ,
	watch: {
		'ready': {
			handler: function() {
				if ( ! this.ready ) return ;
				let document = this.findDocument( this.value || this.query.search ) ;
				this.setValidity( !! document ) ;
			} ,
			immediate: true
		}
	} ,
	mounted: function() {
		if ( this.value && ! this.findDocument( this.value ) ) {
			this.store.getDocument( this.value ) ;
		}
	} ,
	methods: {
		reset: function( event ) {
			// do not use event.target.checked, it does not work well on radios
			if ( event.target.value === this.value ) this.value = null ;
		} ,
		getLinkId: function( document ) {
			var id = document._id ;
			if ( this.property.anyCollection ) id += '@' + document._collection ;
			return id ;
		} ,
		setValidity: function( ok ) {
			this.$refs.value?.setCustomValidity( ok ? '' : ' ' ) ;
		} ,
		findDocument: function( id ) {
			if ( ! id ) return null ;

			id = id.split( '@' )[0] ;

			var inStore = this.store.document( id ) ;
			if ( inStore ) return inStore ;

			if ( ! this.originalDocument ) return null ;
			for( let [propertyName , property] of Object.entries( this.originalSchema.populatables ) ) {
				if ( property.collection !== this.property.collection ) continue ;

				if ( propertyName.split( '.' ).find( el => el === '*' ) ) {
					let [key , , subkey] = propertyName.split( '.' ) ;
					let row = this.originalDocument[key].find( row => {
						if ( ! row[subkey] ) return false ;
						return id === row[subkey]._id || id === row[subkey]?.slugId || id === row[subkey]?.hid ;
					} ) ;
					if ( row ) return row[subkey] ;
				}
				else if ( property.type === 'link' ) {
					let document = this.originalDocument[propertyName] ;
					if ( document && id === document._id || id === document?.slugId || id === document?.hid ) {
						return document ;
					}
				}
				else if ( property.type === 'multiLink' ) {
					let document = this.originalDocument[propertyName].find( document => {
						return id === document._id || id === document?.slugId || id === document?.hid ;
					} ) ;
					if ( document ) return document ;
				}
			}
			return null ;
		}
	}
} ;
</script>