<template>
	<Button-icon class="Debug-button" icon="bug_report" @click="debug()" />
</template>

<style scoped>
.Debug-button {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 5;
	font-size: 1.4em;
}
</style>
