<template>
	<div class="document shortdesk">
		<section>
			<header>{{ i18n('tags.info') }}</header>

			<div class="section-content">
				<div class="grid-align">
					<input-combo v-model="value['name']" v-bind="auto('name')" />
					<input-combo v-model="value['color']" v-bind="auto('color')" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>
