<template>
	<div class="Collection">
		<slot v-if="schema" name="query">
			<Query v-model="query" :schema="schema" />
		</slot>

		<div id="content" class="content">
			<template v-if="ready && collection.length">
				<slot v-for="document in collection" :key="document._id" :store="store" :schema="schema" :document="document">
					<router-link :key="document._id" class="document" :to="{params: {document: document.slugId}}">
						<div>{{ document.hid }}</div>
					</router-link>
				</slot>
			</template>
			<slot v-else-if="ready && ! collection.length" name="empty">
				NOTHING
			</slot>
			<slot v-else name="fetching">
				FETCHING
			</slot>
		</div>

		<slot name="pages">
			<Pagination v-if="collectionMeta" v-model="query" :collection-meta="collectionMeta" />
		</slot>
	</div>
</template>

<script>
import Collection from '@/libRestQuery/js/Collection.js' ;
import SetupCollection from '@/libRestQuery/vue/setups/Collection.js' ;
import Pagination from '@/libRestQuery/vue/components/Pagination.vue' ;
import Query from '@/libRestQuery/vue/components/Query.vue' ;

export default {
	components: {
		Query ,
		Pagination
	} ,
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	setup: SetupCollection ,
	watch: {
		query: {
			handler: function() {
				this.$router.push( {
					query: Collection.flatQuery( this.query )
				} ) ;
			} ,
			deep: true
		}
	}
} ;
</script>

<style scoped>
.document {
	box-shadow: 0 -1px 0px 0px black inset;
	display: block;
}
.document:hover {
	background-color: #EEE;
}

.Query {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 10em 10fr 5em;
	font-size: 1.2em;
}
.sort {
	background-color: transparent;
	border: none;
}

.Pages {
	margin: 1em 0;
	display: grid;
	grid-auto-flow: column;
	place-items: center;
	place-content: center;
}
.page {
	min-width: 0.8em;
	text-align: center;
	padding: 0.6em 1.2em;
	border-radius: 6em;
	background-color: var(--color-positive);
}
.page.selected {
	background-color: var(--color-accent);
}
</style>