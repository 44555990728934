<template>
	<div class="actions">
		<div class="actions-end">
			<Button-icon icon="add" :text="i18n('new')" :to="{params: {document:'NEW'} , query: {method: 'EDIT'}}" />
			<Button-icon icon="download" :text="i18n('export')" />
			<!--
			<Button-icon icon="select" :text="i18n('select')" @click="action('toggleSelectMode')" />
			<Button-icon v-if="selected.size" class="cancel" icon="delete" :text="`${i18n('delete')} (${selected.size})`" @click.prevent="batchRemove()" />
			<Button-icon icon="docs_add_on" text="GselectMode=!selectModeenerate-Fake" @click="generateFake" />
			-->
		</div>
	</div>
</template>

<script>
export default {
	emits: ['action:exec'] ,
	methods: {
		action: function( name , args ) {
			this.$emit( `action:exec` , name , args ) ;
		}
	}
} ;
</script>

<style scoped src="../css/actions.css" />
