<template>
	<input
		type="checkbox"
		:value="value"
		:name="property.name"
		:indeterminate="isIndeterminate"
		:checked="isChecked"
		class="value"
		:class="{hasFilters: hasFilters}"
		@input="update"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		operator: {
			type: String ,
			default: '$eq' ,
			validator( value ) {
				return ['$eq'].includes( value ) ;
			}
		}
	} ,
	computed: {
		isChecked: function() {
			return this.value && this.value !== 'false' ;
		} ,
		isIndeterminate: function() {
			return this.value === null ;
		}
	} ,
	methods: {
		update: function( event ) {
			if ( event.target.checked && this.value === false || this.value === 'false' ) {
				event.target.indeterminate = true ;
				event.target.checked = false ;
				this.value = null ;
			}
			else {
				this.value = event.target.checked ;
			}
		}
	}
} ;
</script>
