<template>
	<input
		v-model="value"
		:name="property.name"
		type="color"
		class="value"
		:required="required"
		:disabled="disabled"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>
