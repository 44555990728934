export default function Account( restQuery ) {
	this.restQuery = restQuery ;

	this.session = JSON.parse( localStorage.getItem( 'session' ) ) ;
	this.regenerateTimeout = null ;

	this.subscribers = new Set() ;

	window.addEventListener( 'storage' , event => {
		if ( event.key === 'session' ) {
			this.updateSession( JSON.parse( event.newValue ) ) ;
		}
	} ) ;

	// FIXME: not great, but working. The problem is that the function
	// check token call this.restQuery.fetch() that need that the
	// constructor of this function to finish before using the token
	setTimeout( () => this.checkToken() , 0 ) ;
}

Account.prototype.onChange = function( callback ) {
	this.subscribers.add( callback ) ;
} ;

Account.prototype.emitChange = function() {
	this.subscribers.forEach( callback => callback( this.session ) ) ;
} ;

Account.prototype.updateSession = function( session , message ) {
	this.session = session ;

	if ( session ) {
		localStorage.setItem( 'session' , JSON.stringify( this.session ) ) ;
		this.scheduleRegenerate() ;
	}
	else {
		localStorage.removeItem( 'session' ) ;
		this.unscheduleRegenerate() ;
	}

	if ( message ) console.log( message ) ;

	this.emitChange() ;

	return this.session ;
} ;

Account.prototype.connect = function( login , password ) {
	var options = {
		method: 'POST' ,
		body: {
			type: 'header' ,
			agentId: '0000000000' ,
			login: login ,
			password: password
		}
	} ;

	return this.restQuery.fetch( '/Users/CREATE-TOKEN' , options )
		.then( session => this.updateSession( { userLogin: login , ...session } , 'CREATE-TOKEN' ) )
		.catch( error => this.updateSession( null , error ) ) ;
} ;

Account.prototype.regenerate = async function() {
	if ( ! this.checkTokenLocal() ) return Promise.resolve( false ) ;

	return this.restQuery.fetch( '/Users/REGENERATE-TOKEN' , { method: 'POST' } )
		.then( session => this.updateSession( { ...this.session , ...session } , 'REGENERATE-TOKEN' ) )
		.catch( error => this.updateSession( null , error ) ) ;
} ;

Account.prototype.disconnect = async function() {
	if ( ! this.checkTokenLocal() ) return Promise.resolve( false ) ;

	return this.restQuery.fetch( '/Users/REVOKE-TOKEN' , { method: 'POST' } )
		.then( () => this.updateSession( null , 'REVOKE-TOKEN' ) )
		.catch( error => this.updateSession( null , error ) ) ;
} ;

Account.prototype.checkToken = async function() {
	if ( ! this.checkTokenLocal() ) return Promise.resolve( false ) ;

	return this.restQuery.fetch( '/Users/CHECK-TOKEN' , { method: 'POST' } )
		.then( session => this.updateSession( session , 'CHECK-TOKEN' ) )
		.catch( error => this.updateSession( null , error ) ) ;
} ;

Account.prototype.checkTokenLocal = function() {
	if ( ! this.session ||
		! this.session.expirationTime ||
		this.tokenGetExpireTime() < 0
	) {
		this.updateSession( null , 'TOKEN EXPIRED - local' ) ;
		return false ;
	}
	return true ;
} ;


Account.prototype.tokenGetExpireTime = function() {
	return new Date( this.session.expirationTime ) - Date.now() ;
} ;

Account.prototype.scheduleRegenerate = function() {
	this.unscheduleRegenerate() ;

	var expireIn = this.tokenGetExpireTime() ;
	var expireInPadded = expireIn - ( 60000 + ( Math.random() * 60000 ) ) ;

	console.log( `Token expire in ${( expireIn / 1000 / 60 ).toFixed( 2 )} minutes,\nRegen scheduled in ${( expireInPadded / 1000 / 60 ).toFixed( 2 )} minutes` ) ;

	this.regenerateTimeout = setTimeout( () => {
		this.regenerate() ;
	} , Math.max( 0 , expireInPadded ) ) ;
} ;

Account.prototype.unscheduleRegenerate = function() {
	clearTimeout( this.regenerateTimeout ) ;
} ;
