<template>
	<div class="multilink">
		<input-link
			v-for="(document,index) in value"
			:key="document"
			v-model="value[index]"
			:property="property"
		/>
		<h1 @click="add()">FIXME+ADD+</h1>
	</div>
</template>

<script>
import mixin from './mixin' ;

import inputLink from './link.vue' ;

export default {
	components: {
		inputLink
	} ,
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: [Array , Object] ,
			default: ()=>[]
		}
	} ,
	methods: {
		add: function() {
			this.value.push( '' ) ;
		}
	}
} ;
</script>