<template>
	<div class="center">
		<h1>{{ document.subtype || 'No subtype' }} - {{ document.sellingPrice || 'No sellingPrice' }} €</h1>
		<!-- <input-combo label="Code barre : " :property="schema.properties['barcode']" :value="document['barcode']" /> -->

		<div v-if="isBarcode" class="barcode">{{ document['barcode'] }}</div>
		<div>{{ document['barcode'] }}</div>
	</div>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument ,
	computed: {
		isBarcode: function() {
			var barcode = parseInt( this.document['barcode'] , 10 ) ;
			return ! isNaN( barcode ) && `${barcode}`.length === 13 ;
		}
	}
} ;
</script>