<template>
	<RestQueryCollection :rest-query="restQuery" />
</template>

<script>
export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	}
} ;
</script>
