<template>
	<div class="multiselect" :class="{hasFilters: hasFilters}">
		<div class="list">
			<label v-for="option in property.in" :key="option" class="name">
				<input
					:value="option"
					:name="property.name"
					type="checkbox"
					:indeterminate="isIndeterminate(option)"
					:checked="isChecked(option)"
					@input="update"
				/>
				{{ option }}
			</label>
		</div>
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	computed: {
		value: {
			get() {
				return Object.assign( {
					$in: [] ,
					$nin: []
				} , this.modelValue ) ;
			} ,
			set( value ) {
				return this.emitValue( value ) ;
			}
		}
	} ,
	methods: {
		isChecked: function( value ) {
			return this.value.$in.includes( value ) ;
		} ,
		isIndeterminate: function( value ) {
			return ! this.value || ( ! this.value.$in.includes( value ) && ! this.value.$nin.includes( value ) ) ;
		} ,
		update: function( event ) {
			var $in = new Set( this.value.$in || [] ) ;
			var $nin = new Set( this.value.$nin || [] ) ;

			if ( event.target.checked ) {
				if ( this.value.$nin.includes( event.target.value ) ) {
					event.target.indeterminate = true ;
					event.target.checked = false ;

					$in.delete( event.target.value ) ;
					$nin.delete( event.target.value ) ;
				}
				else {
					$in.add( event.target.value ) ;
					$nin.delete( event.target.value ) ;
				}
			}
			else {
				$in.delete( event.target.value ) ;
				$nin.add( event.target.value ) ;
			}

			this.value = {
				$in: Array.from( $in ) || null ,
				$nin: Array.from( $nin ) || null
			} ;
		}
	}
} ;
</script>
