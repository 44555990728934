<template>
	<span class="material-symbols" v-text="name" />
	{{ text }}
</template>

<script>
export default {
	props: {
		name: {
			type: String ,
			required: true
		} ,
		text: {
			type: String ,
			default: ''
		}
	}
} ;
</script>