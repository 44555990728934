import { defineStore } from 'pinia' ;

// FIXME:
// Settings like prefers-color-scheme: dark should only be saved when different from browser setting
// Mobile and darkMode should react to change events
var storageKey = 'Config' ;

var config = {} ;
try {
	config = JSON.parse( localStorage.getItem( storageKey ) ) || {} ;
}
catch { /* empty */ }

config.darkMode ??= window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches ;
config.layout ??= ( window.matchMedia && window.matchMedia( '(orientation: portrait)' ).matches ) ? 'Mobile' : 'Desktop' ;

// FIXME: pure crap
document.body.classList.toggle( 'darkMode' , config.darkMode ) ;

export default defineStore( 'Config' , {
	state: () => ( {
		darkMode: config.darkMode || false ,
		layout: config.layout || 'Desktop'
	} ) ,
	actions: {
		switchMobile: function() {
			this.layout = this.layout !== 'Mobile' ? 'Mobile' : 'Desktop' ;
			this.save() ;
		} ,
		switchDarkMode: function() {
			this.setDarkMode( ! this.darkMode ) ;
		} ,
		setDarkMode: function( enable = false ) {
			this.darkMode = enable ;
			document.body.classList.toggle( 'darkMode' , this.darkMode ) ;
			this.save() ;
		} ,
		setLayout: function( layout ) {
			this.layout = layout ;
			this.save() ;
		} ,
		save: function() {
			localStorage.setItem( storageKey , JSON.stringify( this.$state ) ) ;
		}
	}
} ) ;
