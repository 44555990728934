<template>
	<input
		v-model="value"
		type="checkbox"
		:name="property.name"
		class="value"
		:class="{hasFilters: hasFilters}"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: String ,
			default: null
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		value: {
			get() {
				return this.modelValue ? true : null ;
			} ,
			set( value ) {
				this.emitValue( value ? this.property.name : null ) ;
			}
		}
	}
} ;
</script>
