<template>
	<input
		v-model.number="value"
		type="number"
		:name="property.name"
		class="value"
		:class="{hasFilters: hasFilters}"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		operator: {
			type: String ,
			default: '$eq' ,
			validator( value ) {
				return [
					'$eq' ,
					'$lt' ,
					'$lte' ,
					'$gt' ,
					'$gte'
				].includes( value ) ;
			}
		}
	}
} ;
</script>
