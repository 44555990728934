<template>
	<input-combo
		v-if="ready && workingDocument"
		v-model="workingDocument.note"
		:property="schema.properties.note"
		:original-value="document.note"
		:edit="true"
		type="textarea"
	/>
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;

export default {
	props: {
		restQuery: {
			type: Object ,
			required: true
		}
	} ,
	setup: function( props , context ) {
		return SetupDocument( props , context , {
			autoload: true ,
			autoSave: true
		} ) ;
	}
} ;
</script>