<template>
	<input
		v-model.number="value"
		:name="property.name"
		type="number"
		class="value"
		:required="required"
		:disabled="disabled"
		:min="property.min"
		:max="property.max"
		:step="step"
		:placeholder="placeholder"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Number ,
			default: null
		}
	} ,
	computed: {
		value: {
			get() {
				return this.modelValue ;
			} ,
			set( value ) {
				let val = parseFloat( value ) ;
				if ( isNaN( val ) ) return ;
				this.emitValue( val ) ;
			}
		} ,
		step: function() {
			return this.property.type === 'integer' ? '1' : 'any' ;
		}
	}
} ;
</script>





