<template>
	<input
		v-model="value"
		:required="required"
		class="value"
		:disabled="disabled"
		:name="property.name"
		type="checkbox"
	/>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Boolean ,
			default: false
		}
	}
} ;
</script>
