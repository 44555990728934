export default {
	inject: ['schema'] ,
	props: {
		modelValue: {
			type: Object ,
			required: true
		}
	} ,
	data: function() {
		return {
			debounceSearchTimeout: null
		} ;
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		query: {
			get() {
				return this.modelValue ;
			} ,
			set( query ) {
				// Is generally not called, the watcher is the one that
				// trigger the emits
				this.$emit( 'update:modelValue' , query ) ;
			}
		} ,
		debounceSearch: {
			get() {
				return this.query.search ;
			} ,
			set( search ) {
				clearTimeout( this.debounceSearchTimeout ) ;
				this.debounceSearchTimeout = setTimeout( () => {
					this.query.search = search ;
				} , 500 ) ;
			}
		}
	} ,
	watch: {
		query: {
			handler: function() {
				this.$emit( 'update:modelValue' , this.query ) ;
			} ,
			deep: true
		}
	} ,
	mounted: function() {
		for( let key of Object.keys( this.schema.filtrables ) ) {
			if ( ! this.query.filters[key] ) {
				this.query.filters[key] = {} ;
			}
		}
	}
} ;
