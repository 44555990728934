<template>
	<h1>{{ document.client?.hid || 'No client' }}</h1>
	<input-combo v-bind="auto('state')" />
	<input-combo v-bind="auto('orderNumber')" />
	<input-combo v-bind="auto('departureDate')" />
</template>

<script>
import SetupDocument from '@/libRestQuery/vue/setups/Document.js' ;
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	setup: SetupDocument
} ;
</script>