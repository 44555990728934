<template>
	<select v-model="value" :required="required" class="value" :disabled="disabled" :name="property.name">
		<!--
		<option v-if="! value" hidden disabled selected value />
		-->
		<option v-if="! value" disabled selected :value="null">{{ placeholder }}</option>
		<option v-if="! required" :value="null" />
		<option v-for="option in property.in" :key="option" :value="option">
			{{ option }}
		</option>
	</select>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin]
} ;
</script>
