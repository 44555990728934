import Collection from '@/libRestQuery/js/Collection.js' ;
import { isEqual } from 'lodash-es' ;

export default function Document( restQuery , collection , id ) {
	if ( ! restQuery || ! collection || ! id ) {
		throw new Error( 'RestQuery.Document: Invalid parameters' ) ;
	}

	this.restQuery = restQuery ;
	this.collection = collection ;
	this.id = id ;
}

Document.prototype.rawQuery = function( options ) {
	var path = [this.collection.name , this.id] ;

	if ( options.restQueryMethod ) {
		path.push( options.restQueryMethod ) ;
	}

	if ( options.queryString && typeof options.queryString !== 'string' ) {
		options.queryString = Collection.queryToString( options.queryString ) ;
	}

	return this.restQuery.fetch( path.join( '/' ) , options ) ;
} ;

/**************************/
/* Helpers, call rawQuery */
/**************************/
Document.prototype.get = async function() {
	await this.collection.getSchema() ;

	return this.rawQuery( {
		method: 'GET' ,
		queryString: {
			populate: Object.keys( this.collection.schema.populatables )
		}
	} ) ;
} ;

Document.prototype.update = function( document , originalDocument ) {
	var newDocument = Document.diff( document , this.collection.schema.createWorkingDocument( originalDocument ) ) ;

	if ( ! newDocument ) {
		console.log( 'Document.prototype.update: No changes' ) ;
		return Promise.resolve( false ) ;
	}

	return this.rawQuery( {
		method: 'PATCH' ,
		body: Document.flatten( newDocument )
	} ) ;
} ;

Document.prototype.replace = function( document ) {
	return this.rawQuery( {
		method: 'PUT' ,
		body: document
	} ) ;
} ;

Document.prototype.delete = function() {
	return this.rawQuery( {
		method: 'DELETE'
	} ) ;
} ;

/*********************/
/* Documents methods */
/*********************/
Document.prototype.method = function( restQueryMethod , options ) {
	return this.rawQuery( Object.assign( {
		restQueryMethod: restQueryMethod ,
		method: 'POST'
	} ,	options ) ) ;
} ;

Document.prototype.diff = function( document , originalDocument ) {
	return Document.diff( document , originalDocument ) ;
} ;

/******************/
/* Static methods */
/******************/
Document.diff = function( workingDocument , originalDocument ) {
	console.log( 'Diff on:' , workingDocument , originalDocument ) ;
	//FIXME: return structuredClone() values
	let patch = {} ;
	for( let [name , workingProperty] of Object.entries( workingDocument ) ) {
		if ( workingProperty === '' && originalDocument[name] === null ) continue ;
		else if ( isEqual( workingProperty , originalDocument[name] ) ) continue ;
		// Link case
		else if ( workingProperty === originalDocument[name]?._id ) continue ;
		// MultiLink case
		else if (
			Array.isArray( workingProperty ) &&
			isEqual( workingProperty , originalDocument[name].map( element=>element._id ) )
		) continue ;
		else if ( Array.isArray( workingProperty ) ) {
			if ( workingProperty.length === originalDocument[name].length ) {
				var arrayDiff = workingProperty.filter( ( subWorkingDocument , index ) => {
					if ( ! originalDocument[name][index] ) return subWorkingDocument ;
					return Document.diff( subWorkingDocument , originalDocument[name][index] ) ;
				} ) ;

				// console.log( 'DIFF' , name , arrayDiff ) ;
				if ( ! arrayDiff.length ) {
					continue ;
				}
			}
		}

		/*
		// FIXME: should diff correctly on an array, but Document.flatten
		// doesn't flatten array for now, so don't uncomment without that
		// and a rewrite
		// multiLink case too, but all arrays too
		else if ( Array.isArray( originalDocument[name] ) ) {
			patch[name] = workingProperty.map( ( bob , index ) => {
				return Document.diff( bob , originalDocument[name][index] ) ;
			} ) ;
			continue ;
		}
		if ( Array.isArray( originalDocument[name] ) ) {
			// patch[name] = Document.diff( workingProperty , originalDocument[name] ) ;
			var fixme = [] ;
			for( let index = 0 ; index < workingProperty.length ; index++ ) {
				let aa = Document.diff( workingProperty[index] , originalDocument[name][index] ) ;
				if ( aa ) {
					fixme.push( aa ) ;
				}
			}
			if ( fixme.length ) {
				patch[name] = fixme ;
			}
			continue ;
		}
		*/
		patch[name] = workingProperty ;
	}

	return Object.keys( patch ).length ? patch : null ;
} ;

// INFO: Doesn't flatten anything other than Objects
Document.flatten = function( document , basePath = null , globalObject = {} ) {
	for( let [key , value] of Object.entries( document ) ) {
		let path = basePath ? `${basePath}.${key}` : key ;

		if ( value && value.constructor === Object ) {
			Document.flatten( value , path , globalObject ) ;
			continue ;
		}

		globalObject[ path ] = value ;
	}

	return globalObject ;
} ;

// INFO: Doesn't unFlatten anything other than Objects
Document.unFlatten = function( document ) {
	var object = {} ;
	for( let [key , value] of Object.entries( document ) ) {
		let keys = key.split( '.' ) ;

		let treeObject = object ;
		for( let i = 0 ; i < keys.length ; i++ ) {
			if ( i === keys.length - 1 ) {
				treeObject[ keys[i] ] = value || null ;
			}
			else if ( ! treeObject[ keys[i] ] ) {
				treeObject[ keys[i] ] = {} ;
			}

			treeObject = treeObject[ keys[i] ] ;
		}
	}

	return object ;
} ;