<template>
	<div class="value file">
		{{ value.filename }}
		<input :required="required" type="file" />
	</div>
</template>

<script>
import mixin from './mixin' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: Object ,
			default: () => {}
		}
	}
} ;
</script>
