export default {
	props: {
		document: {
			type: Object ,
			default: () => {}
		} ,
		schema: {
			type: Object ,
			required: true
		} ,
		modelValue: {
			type: Object ,
			required: true
		} ,
		edit: {
			type: Boolean ,
			default: false
		}
	} ,
	emits: ['update:modelValue'] ,
	computed: {
		value: {
			get() {
				return this.edit ? this.modelValue : this.document ;
			} ,
			set( value ) {
				if ( ! this.edit ) {
					console.log( 'WARNING: Component should not emit values' , value ) ;
					alert( 'WARNING: Component should not emit values' ) ;
					return ;
				}
				this.$emit( 'update:modelValue' , value ) ;
			}
		}
	} ,
	methods: {
		auto: function( propertyName , label = true ) {
			return {
				property: this.schema.properties[propertyName] ,
				label: label ,
				edit: this.edit ,
				originalValue: this.document?.[propertyName] ,
				placeholder: this.schema.properties[propertyName].optional ? 'Champ facultatif' : 'Champ obligatoire'
			} ;
		} ,
		createFrom: function( collection ) {
			return {
				params: {
					app: collection ,
					document: 'NEW'
				} ,
				query: {
					method: 'EDIT' ,
					baseDocument: JSON.stringify( { client: { _id: this.document._id } } )
				}
			} ;
		}
	}
} ;