import Collections from '@/libRestQuery/vue/store/Collections/index.js' ;
import { toRaw , provide , watch , computed , ref } from 'vue' ;

export default function( props , context , restQuery ) {
	const options = Object.assign( {
		autoload: true ,
		autoSave: false
	} , props.restQuery , restQuery ) ;

	const collectionName = options.collection ;
	const documentId = options.document ;
	const baseDocument = options.baseDocument || null ;

	const store = Collections( collectionName ) ;

	const ready = computed( () => !! ( schema.value && ( documentId ? document.value : true ) ) ) ;

	const schema = computed( () => store.schema ) ;
	const document = computed( () => documentId && store.document( documentId ) ) ;
	const workingDocument = ref( null ) ;

	const debounceAutoSaveTimeout = ref( null ) ;

	provide( 'schema' , schema ) ;
	provide( 'document' , document ) ;

	const createWorkingDocument = async function( force ) {
		if ( workingDocument.value && ! force ) return Promise.resolve( workingDocument.value ) ;

		return store.getSchema().then( () => {
			if ( ! schema.value ) console.log( 'strange bug on' , collectionName ) ;
			workingDocument.value = schema.value.createWorkingDocument( { ...baseDocument , ...toRaw( document.value ) } ) ;

			if ( options.autoSave ) {
				watch( () => workingDocument , () => {
					clearTimeout( debounceAutoSaveTimeout.value ) ;
					debounceAutoSaveTimeout.value = setTimeout( () => save() , 500 ) ;
				}
				, { deep: true } ) ;
			}
			return workingDocument.value ;
		} ) ;
	} ;

	const save = function() {
		return ( documentId ? update() : create() )
			.then( response => {
				if ( response ) {
					console.log( 'Document saved' ) ;
				}
				return response ;
			} )
			.catch( error => {
				console.log( error ) ;
				throw ( error ) ;
			} ) ;
	} ;

	const create = function() {
		return store.createDocument( workingDocument.value ) ;
	} ;

	const update = function() {
		return store.updateDocument( document.value._id , workingDocument.value , document.value )
			.then( hasChanges => hasChanges ? refreshFull() : false ) ;
	} ;

	const remove = function() {
		return store.deleteDocument( document.value._id ) ;
	} ;

	const refresh = function() {
		if ( ! documentId ) return Promise.resolve( null ) ;
		return store.getDocument( documentId ) ;
	} ;

	const refreshFull = function() {
		return refresh().then( response => {
			if ( response ) createWorkingDocument( true ) ;
			return response ;
		} ) ;
	} ;

	const freeze = function() {
		if ( ! documentId ) return Promise.resolve( null ) ;
		return store.documentMethod( documentId , 'FREEZE' ).then( ()=>refreshFull() ) ;
	} ;

	const unfreeze = function() {
		if ( ! documentId ) return Promise.resolve( null ) ;
		return store.documentMethod( documentId , 'UNFREEZE' ).then( ()=>refreshFull() ) ;
	} ;

	if ( documentId && ! document.value && options.autoload ) refresh().then( () => createWorkingDocument( true ) ) ;
	else createWorkingDocument() ;

	return {
		collectionName ,
		store ,

		ready ,
		schema ,
		document ,
		workingDocument ,

		createWorkingDocument ,
		save ,
		create ,
		update ,
		remove ,
		refresh ,
		freeze ,
		unfreeze
	} ;
}
