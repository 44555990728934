<template>
	<div class="document shortdesk">
		<section>
			<header>Définition des portants</header>
			<div class="section-content">
				<div class="grid-align">
					<input-combo v-model="value['name']" v-bind="auto('name')" />
					<input-combo v-model="value['season']" v-bind="auto('season')" />
					<input-combo v-model="value['year']" v-bind="auto('year')" />
					<input-combo v-model="value['rows']" v-bind="auto('rows')" />
					<input-combo v-model="value['archived']" v-bind="auto('archived')" />
				</div>
			</div>
			<div class="section-content">
				<input-combo v-model="value['products']" v-bind="auto('products')" />
			</div>
		</section>
		<section v-if="!edit" class="meta">
			<div class="section-content grid-align">
				<input-combo :model-value="document['creationDate']" :edit="false" v-bind="auto('creationDate')" />
				<input-combo :model-value="document['creationUser']" :edit="false" v-bind="auto('creationUser')" />
				<input-combo :model-value="document['modificationDate']" :edit="false" v-bind="auto('modificationDate')" />
				<input-combo :model-value="document['modificationUser']" :edit="false" v-bind="auto('modificationUser')" />
			</div>
		</section>
	</div>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin]
} ;
</script>

