import { defineStore } from 'pinia' ;
import actions from './actions.js' ;
import restQuery from '@/restQuery/index.js' ;

export default defineStore( `RestQueryAccount` , {
	state: function() {
		return {
			session: useRestqueryAccount()
		} ;
	} ,
	getters: {
		restQueryAccount: () => restQuery.account
	} ,
	actions
} ) ;


import { ref } from 'vue' ;
function useRestqueryAccount() {
	const session = ref( null ) ;
	session.value = restQuery.account.session ;

	restQuery.account.onChange( sessionValue => {
		session.value = sessionValue ;
	} ) ;

	return session ;
}