export default function() {
	return {
		ready: false ,
		// schema: null ,
		// url: [...documentId]
		collections: {} ,

		// url: { meta }
		collectionsMeta: {} ,

		// _id: { document }
		documents: {} ,

		// slug: { documentId }
		documentsSlugs: {} ,

		// hid: { documentId }
		documentsHids: {}
	} ;
}
