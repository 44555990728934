<template>
	<form class="Query" @submit.prevent>
		<input-filter
			v-model="query.method"
			:property="{name: 'OPEN-PERIODS'}"
			:label="i18n('open-periods')"
			type="method"
		/>
		<input-filter
			v-model="query.filters['clientType']"
			:property="schema.filtrables['clientType']"
		/>
		<input-filter
			v-model="query.filters['tags']"
			:property="schema.filtrables['tags']"
		/>

		<div class="flex place-items-center">
			<input-filter
				v-model="query.filters['creationDate']"
				label="Du"
				:property="schema.filtrables['creationDate']"
				operator="$gte"
			/>
			<input-filter
				v-model="query.filters['creationDate']"
				label="au"
				:property="schema.filtrables['creationDate']"
				operator="$lte"
			/>
		</div>

		<input
			v-model="debounceSearch"
			autocomplete="off"
			:placeholder="i18n('search')"
			type="text"
			class="search"
			name="search"
		/>

		<div class="sort">
			<select v-model="query.sortName" class="sortName" name="sortName">
				<option v-for="sortable in schema.indexes" :key="sortable.name" :value="sortable.name">
					{{ sortable.localName }}
				</option>
			</select>

			<label class="sortOrder">
				<input v-model="query.sortOrder" class="hidden" true-value="1" false-value="-1" name="sortOrder" type="checkbox" />
				<span v-if="query.sortOrder==='1'" class="material-symbols">north_east</span>
				<span v-else class="material-symbols">south_east</span>
			</label>
		</div>
		<select v-model="query.limit" class="limit" name="limit">
			<option>15</option>
			<option>30</option>
			<option>60</option>
			<option>120</option>
		</select>
	</form>
</template>

<script>
import mixinQuery from './mixin-query.js' ;

export default {
	mixins: [mixinQuery]
} ;
</script>