<template>
	<span class="value date">{{ localValue }}</span>
</template>

<script>
import mixin from './mixin.js' ;

export default {
	mixins: [mixin] ,
	props: {
		modelValue: {
			type: String ,
			default: ''
		}
	} ,
	computed: {
		localValue: function() {
			var value = this.toDateObject( this.value ) ;
			if ( ! this.value || ! value ) return this.value ;

			var format = this.property.formatHint || {
				year: 'numeric' ,
				month: '2-digit' ,
				day: '2-digit'
			} ;

			return new Intl.DateTimeFormat( 'fr-FR' , format ).format( value ) ;
		}
	} ,
	methods: {
		toDateObject: function( date ) {
			if ( ! ( date instanceof Date ) ) {
				if ( typeof date === 'string' ) {
					date = new Date( date ) ;
				}
			}
			return date ;
		}
	}
} ;
</script>
